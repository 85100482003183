import "./Header.scss";
import Pusher from "pusher-js";
import {
  FiberManualRecord,
  PostAdd,
  PowerSettingsNew,
  TableChart,
  Web,
} from "@material-ui/icons";
import {
  AppBar,
  Avatar,
  Badge,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import {
  PLATFORM_IS_MAKAAN,
  changeOwnerAgentStatus,
  getUser,
  getVariousConfig,
  logout,
  getOwnerUserStatusBackwardCompatible,
} from "../../redux/actions/login.actions";
import React, { useEffect, useRef, useState, Fragment } from "react";
import {
  AGENT_STATUS_UPDATE,
  changeAgentStatus,
  clientInactiveBeforeCall,
  pullCase,
} from "../../redux/actions/teleSalesDashboard.actions";
import {
  callVariousConfigApis,
  getNotifications,
  readNotification,
} from "../../redux/actions/header.actions";
import ErrorDialog from "../ErrorDialog";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BallotIcon from "@material-ui/icons/Ballot";
import Constants from "../../utils/CommonConstants";
import Favicon from "../../images/favicon.png";
import MakaanFavicon from "../../imgmk1/favicon.png";
import MenuIcon from "@material-ui/icons/Menu";
import Meta from "react-helmet";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { gaTrackingFn } from "../../tracking/tracking";
import { pullTMCase } from "../../redux/actions/tenantDashboard.actions";
import renewalDashboardTrackingMap from "../../tracking/renewalDashboard";
import usePrevious from "../../hooks/usePrevious";
import withRouter from "../../utils/withRouter";
import NavLink from "../../utils/NavLink";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import constant from "../../utils/CommonConstants";
import { Skeleton } from "@material-ui/lab";
import Store from "../../utils/localStorage";
import { navigations } from "../../utils/NavigationUtils";
const { readBrowserStorage } = Store(Constants.CHECK_LSQ);

const ContentUserMenu = {
  label: "Content Package Management",
  route: "/content-package-page",
  icon: BallotIcon,
};

const FinanceUserMenu = {
  label: "Sales Incentive Payout",
  route: "/incentive-payout",
  icon: AccountBalanceWalletIcon,
};

const AudienceMaximizerAgentMenu = {
  label: "Ad Product",
  icon: PostAdd,
  nestedMenus: [
    { label: "Audience Maximizer", route: "/audience-maximizer", icon: Web },
  ],
};

const LeadFeedbackAgentMenu = {
  label: "Ad Product",
  icon: PostAdd,
  nestedMenus: [{ label: "Lead Feedback", route: "/lead-feedback", icon: Web }],
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  isLoggedOut: state.isLoggedOut,
  variousConfig: state.variousConfig,
  isPlatformMakaan: state.isPlatformMakaan,
  hasLoginFailed: state.hasLoginFailed,
  agentStatus: state.agentStatus,
  agentStatusApiLoading: state.agentStatusApiLoading,
  callingQueueOpportunity: state.callingQueueOpportunity,
  ownerCallingStatus: state.ownerCallingStatus,
  timerStartTime: state.timerStartTime,
  enableRenewalDashboard: state.enableRenewalDashboard,
  oldNotifications: state.oldNotifications,
  errorDialogMessage: state.errorDialogMessage,
  canCallVariousConfigApis: state.canCallVariousConfigApis,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: bindActionCreators(logout, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
    getVariousConfig: bindActionCreators(getVariousConfig, dispatch),
    changeAgentStatus: bindActionCreators(changeAgentStatus, dispatch),
    pullCase: bindActionCreators(pullCase, dispatch),
    pullTMCase: bindActionCreators(pullTMCase, dispatch),
    readNotification: bindActionCreators(readNotification, dispatch),
    getNotifications: bindActionCreators(getNotifications, dispatch),
    gaTrackingFn: bindActionCreators(gaTrackingFn, dispatch),
    changeOwnerAgentStatus: bindActionCreators(changeOwnerAgentStatus, dispatch),
    callVariousConfigApis: bindActionCreators(callVariousConfigApis, dispatch),
    getOwnerUserStatus: bindActionCreators(
      getOwnerUserStatusBackwardCompatible,
      dispatch
    ),
    dispatch,
  };
};

const styles = {
  menuItem: {
    color: "#B3B4B5",
  },
};

const Header = (props) => {
  const {
    isPlatformMakaan,
    agentStatus,
    ownerCallingStatus,
    userInfo = null,
    canCallVariousConfigApis,
  } = props;
  const { ACTIVE, INACTIVE } = Constants.agentStatus;
  const {
    roles = [],
    isOwnerTelesalesAgent,
    isBrokerTelesalesAgent,
    isBuilderTelesalesAgent,
  } = userInfo || {};

  const [state, _setState] = useState({
    open: false,
    menuLabel: null,
    shouldPullCase: false,
    isIconHovered: false,
    statusMessage: "",
    openNotificationDialog: false,
    errorDialogMessage: "",
  });
  const { errorDialogMessage } = state;

  const { readNotification, getNotifications } = props;
  const [newNotifications, setNewNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pusherConnectedFlag, setPusherConnectedFlag] = useState(false);
  const [pusherInstance, setPusherInstance] = React.useState();
  const [pusherChannel, setPusherChannel] = React.useState();
  const [oldNotifications, setOldNotifications] = React.useState([]);
  const [todaysNotifications, setTodaysNotifications] = React.useState([]);
  const setState = (newState) => {
    _setState((state) => ({ ...state, ...newState }));
  };

  const previousProps = usePrevious(props);

  const navigationsRef = useRef([]);
  const ownerActivityStatusFetched = useRef(false);

  const isAudienceMaximizerAgent = userInfo?.roles?.includes(
    constant.audienceMaximizerAgentRole
  );
  useEffect(() => {
    let { location } = props.router;
    if (location.pathname === "/renewal-dashboard") {
      const { gaTrackingFn } = props;
      if (gaTrackingFn) {
        const { RENEWAL_DASHBOARD_VISITED } = renewalDashboardTrackingMap;
        gaTrackingFn(RENEWAL_DASHBOARD_VISITED, {
          type: "RENEWAL_DASHBOARD_VISIT",
        });
      }
    }
  }, [props.router.location]);

  let handleNotificationEventReceived = (eventJSON) => {
    let event = JSON.parse(eventJSON);
    setNewNotifications((prevNewNotifications) => {
      return [event, ...prevNewNotifications];
    });
  };

  let handleConnectToPusher = () => {
    const { userInfo } = props;
    if (!pusherConnectedFlag) {
      let pusherOptions = {
        cluster: "ap2",
      };
      if (userInfo && userInfo.id) {
        let tempInstance = new Pusher(
          process.env.PAYMENT_NOTIFICATION_PUSHER_APP_KEY,
          pusherOptions
        );
        let tempChannel = tempInstance.subscribe(userInfo.id + "");
        tempChannel.bind(
          "payment-notification-connection-event",
          handleNotificationEventReceived
        );
        setPusherInstance(tempInstance);
        setPusherChannel(tempChannel);
        setPusherConnectedFlag(true);
      }
    }
  };

  let handleDisconnectFromPusher = () => {
    if (pusherConnectedFlag) {
      pusherChannel.unbind(
        "payment-notification-connection-event",
        handleNotificationEventReceived
      );

      pusherInstance.unsubscribe(userInfo.id + "");

      setPusherConnectedFlag(false);
    }
  };

  useEffect(handleConnectToPusher, [props.userInfo]);
  useEffect(() => {
    return handleDisconnectFromPusher;
  }, []);

  useEffect(() => {
    setState({ errorDialogMessage: props.errorDialogMessage });
  }, [props.errorDialogMessage]);

  const setNavigationPaths = (props) => {
    const { userInfo, enableRenewalDashboard } = props;
    if (userInfo && userInfo.isFinanceUser) {
      navigationsRef.current = [FinanceUserMenu];
    } else if (userInfo && userInfo.isContentUser) {
      if (userInfo.isContentUserOnly) {
        navigationsRef.current = [ContentUserMenu];
      } else {
        let navigationsCopy = navigations.slice();
        navigationsCopy.splice(3, 0, ContentUserMenu);
        navigationsRef.current = navigationsCopy;
      }
    } else if (isAudienceMaximizerAgent) {
      navigationsRef.current = [AudienceMaximizerAgentMenu];
    } else if (userInfo?.roles?.includes(constant.leadFeedbackAgent)) {
      navigationsRef.current = [LeadFeedbackAgentMenu];
    } else {
      navigationsRef.current = navigations;
      // will be removed in future
      if (enableRenewalDashboard) {
        navigationsRef.current = [
          ...navigationsRef.current,
          {
            label: "Renewal Dashboard",
            route: "/renewal-dashboard",
            icon: TableChart,
          },
        ];
      }
    }
  };

  const setPlatformType = () => {
    const { dispatch } = props;
    if (
      window &&
      window.location &&
      Constants.makaanHostNameList.includes(window.location.hostname)
    ) {
      dispatch({
        type: PLATFORM_IS_MAKAAN,
        platformIsMakaan: true,
      });
    }
  };

  useEffect(() => {
    setNavigationPaths(props);

    const { userInfo, getUser } = props;

    const onMountApis = [];

    if (Object.keys(userInfo || {}).length === 0) {
      onMountApis.push(getUser());
    }
    // if (
    //   !Object.keys(variousConfig || {}).length &&
    //   Object.keys(userInfo || {}).length
    // ) {
    //   onMountApis.push(getVariousConfig(userInfo.isContentUserOnly));
    // }
    onMountApis.length && Promise.all(onMountApis);
    setPlatformType();
  }, []);

  useEffect(() => {
    const {
      variousConfig: { ownerAgentStatusMapping = {} } = {},
      userInfo = {},
      getOwnerUserStatus,
      dispatch,
    } = props;
    const { roles = [] } = userInfo || {};
    const { housingOwnerAgentRole } = Constants;
    if (
      roles.includes(housingOwnerAgentRole) &&
      Object.keys(ownerAgentStatusMapping).length &&
      process.env.SAPPHIRE_OWNER_FLAG &&
      !ownerActivityStatusFetched.current
    ) {
      getOwnerUserStatus().then((status) => {
        dispatch({
          type: AGENT_STATUS_UPDATE,
          payload: status,
        });
        ownerActivityStatusFetched.current = true;
      });
    }
  }, [props.variousConfig]);

  const gotoLogin = () => {
    props.router.navigate(
      "/?redirectTo=" +
        encodeURIComponent(
          props.router.location.pathname + props.router.location.search
        )
    );
  };

  const handleDialogClose = () => {
    setState({ errorDialogMessage: "" });
  };

  let eventSource = undefined;
  const isNotificationVisible =
    roles && Constants.NotificationRoles.some((role) => roles.includes(role));

  useEffect(() => {
    if (props.userInfo && props.userInfo.id && isNotificationVisible) {
      eventSource = new EventSource("sapna/sse/paymentNotifications/subscribe");
      eventSource.addEventListener("paymentNotification", (event) => {
        setNewNotifications((prevNotification) => [
          JSON.parse(JSON.parse(event.data)),
          ...prevNotification,
        ]);
      });
    }
    if (props.userInfo?.id && !isAudienceMaximizerAgent && isNotificationVisible) {
      getNotifications();
    }
  }, [props.userInfo]);

  useEffect(() => {
    if (props.userInfo) {
      !props.variousConfig &&
        props.userInfo &&
        props.getVariousConfig(props.userInfo.isContentUserOnly);
    }
  }, [canCallVariousConfigApis]);

  useEffect(() => {
    const {
      userInfo,
      callingQueueOpportunity,
      router,
      agentStatus,
      pullCase,
      pullTMCase,
    } = previousProps;
    const nextProps = props;
    const { ACTIVE } = Constants.agentStatus;
    const { shouldPullCase } = state;
    if (
      props.router.location.pathname !== "/" &&
      (nextProps.isLoggedOut || nextProps.hasLoginFailed)
    ) {
      gotoLogin();
    }
    if (nextProps.userInfo !== userInfo) {
      // !nextProps.variousConfig &&
      //   nextProps.userInfo &&
      //   props.getVariousConfig(nextProps.userInfo.isContentUserOnly);
      setNavigationPaths(nextProps);
    }
    const { pathname } = nextProps.router.location;
    if (
      pathname !== "/" &&
      nextProps.userInfo &&
      nextProps.userInfo.isContentUserOnly &&
      !(
        pathname === ContentUserMenu.route || /^\/opportunity\/(\d)+$/.test(pathname)
      )
    ) {
      props.router.navigate(ContentUserMenu.route, { replace: true });
    }
    if (!pathname.includes("/audience-maximizer") && isAudienceMaximizerAgent) {
      router.navigate("/audience-maximizer");
    }
    if (
      nextProps.callingQueueOpportunity &&
      nextProps.callingQueueOpportunity !== callingQueueOpportunity
    ) {
      const { opportunityId } = nextProps.callingQueueOpportunity;
      if (!opportunityId || opportunityId === "undefined") {
        router.navigate("/home");
      } else {
        if (
          /tenant-*/.test(pathname) ||
          (!isOwnerTelesalesAgent &&
            !isBrokerTelesalesAgent &&
            !isBuilderTelesalesAgent)
        ) {
          router.navigate(`/tenant-opportunity/${opportunityId}`);
        } else if (isBuilderTelesalesAgent) {
          router.navigate(`/builder-opportunity/${opportunityId}`);
        } else if (isBrokerTelesalesAgent) {
          router.navigate(`/broker-opportunity/${opportunityId}`);
        } else {
          router.navigate(`/owner-opportunity/${opportunityId}`);
        }
      }
    }

    if (
      nextProps.agentStatus !== agentStatus &&
      nextProps.agentStatus == ACTIVE &&
      shouldPullCase
    ) {
      if (
        /tenant-*/.test(pathname) ||
        (!isOwnerTelesalesAgent &&
          !isBrokerTelesalesAgent &&
          !isBuilderTelesalesAgent)
      ) {
        pullTMCase();
      } else if (isBrokerTelesalesAgent) {
        pullCase(Constants.brokerTelesalesAgentRole).then((res) => {
          const {
            response: {
              data: { statusCode = "", error: { msg = "" } = {} } = {},
            } = {},
          } = res;
          if (statusCode == "400") {
            setState({
              errorDialogMessage: msg,
            });
          }
        });
      } else if (isBuilderTelesalesAgent) {
        pullCase(constant.builderTelesalesAgentRole);
      } else {
        pullCase();
      }
      setState({
        shouldPullCase: false,
      });
    }

    if (nextProps.agentStatus !== agentStatus) {
      if (nextProps.agentStatus == ACTIVE) {
        handleConnectToPusher();
      } else if (nextProps.agentStatus == INACTIVE) {
        handleDisconnectFromPusher();
      }
    }
  }, [props]);

  const handleSeparateTodaysNotifications = () => {
    if (props.oldNotifications && props.oldNotifications.length) {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let tempTodaysNotifications = props.oldNotifications.filter(
        (notificationData) => {
          return (
            new Date(notificationData.createdAt).getTime() >= currentDate.getTime()
          );
        }
      );

      let tempOldNotifications = props.oldNotifications.filter(
        (notificationData) => {
          return (
            new Date(notificationData.createdAt).getTime() < currentDate.getTime()
          );
        }
      );

      setTodaysNotifications(tempTodaysNotifications);
      setOldNotifications(tempOldNotifications);
    }
  };

  useEffect(handleSeparateTodaysNotifications, [props.oldNotifications]);

  const logoutClicked = () => {
    setState({ open: false });
    props.logout();
  };

  const getMeta = (headObj) => {
    const AppName = headObj.isPlatformMakaan ? "Makaan" : "Housing";
    return {
      title: headObj.title || `${AppName} Sales`,
      link: [
        {
          href: "https://fonts.googleapis.com/icon?family=Material+Icons",
          rel: "stylesheet",
        },
        {
          href: headObj.isPlatformMakaan ? MakaanFavicon : Favicon,
          rel: "shortcut icon",
          type: "image/png",
        },
      ],
      meta: [
        {
          charset: "utf-8",
        },
        {
          name: "description",
          content:
            headObj.description || `This is an application for ${AppName} Sales!`,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
      ],
    };
  };

  const handleToggle = () => {
    setState({ open: !state.open });
  };

  const handleOpen = () => {
    setState({
      open: true,
    });
  };

  const handleClose = (route) => {
    const { agentStatus } = props;
    if (agentStatus === Constants.agentStatus.ACTIVE) {
      window.open(route);
    }
    setState({
      open: false,
      menuLabel: null,
    });
  };

  const showStatus = (isIconHovered) => {
    const { agentStatus } = props;
    let statusMessage =
      agentStatus === Constants.agentStatus.ACTIVE
        ? "Agent is active"
        : "Agent is inactive";
    setState({
      isIconHovered,
      statusMessage,
    });
  };

  const openSubMenu = (menuLabel = null) => {
    const mLabel = state.menuLabel;
    setState({
      menuLabel: mLabel === menuLabel ? null : menuLabel,
    });
  };

  const getMenuLink = (navigation, clickAction) => {
    const { userInfo: { roles: userRoles = [] } = {} } = props;

    if (
      navigation?.roles &&
      !navigation.roles.some((roles) => userRoles.includes(roles))
    ) {
      return null;
    }
    const NavigationIcon = navigation.icon;
    return (
      <MenuItem
        style={styles.menuItem}
        onClick={() => clickAction(navigation.route)}
      >
        <NavigationIcon style={{ color: "#B3B4B5", paddingRight: "20px" }} />
        {navigation.label}
      </MenuItem>
    );
  };
  const getMenuItem = (navigation) => {
    const { agentStatus } = props;
    if (navigation.nestedMenus) {
      return (
        <Fragment key={`header_link_${navigation.label}`}>
          <div
            className="menu-item"
            key={`header_link_${navigation.label}`}
            onClick={() => openSubMenu(navigation.label)}
          >
            {getMenuLink(navigation, openSubMenu)}
          </div>
          {state.menuLabel === navigation.label && (
            <div style={{ paddingLeft: "15px" }}>
              {navigation.nestedMenus.map((menu) => {
                if (agentStatus === Constants.agentStatus.INACTIVE) {
                  return (
                    <div className="menu-item" key={`header_sub_link_${menu.label}`}>
                      <NavLink
                        to={menu.route}
                        activeClassName={"menu-item-selected"}
                      >
                        {getMenuLink(menu, handleClose)}
                      </NavLink>
                    </div>
                  );
                } else {
                  return (
                    <div className="menu-item" key={`header_sub_link_${menu.label}`}>
                      <NavLink
                        to={menu.route}
                        activeClassName={"menu-item-selected"}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {getMenuLink(menu, handleClose)}
                      </NavLink>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </Fragment>
      );
    } else {
      if (agentStatus === Constants.agentStatus.INACTIVE) {
        return (
          <div className="menu-item" key={`header_link_${navigation.label}`}>
            <NavLink to={navigation.route} activeClassName={"menu-item-selected"}>
              {getMenuLink(navigation, handleClose)}
            </NavLink>
          </div>
        );
      } else {
        return (
          <div className="menu-item" key={`header_link_${navigation.label}`}>
            <NavLink
              to={navigation.route}
              activeClassName={"menu-item-selected"}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {getMenuLink(navigation, handleClose)}
            </NavLink>
          </div>
        );
      }
    }
  };

  const handleOnline = () => {
    const {
      changeAgentStatus,
      agentStatus,
      router,
      ownerCallingStatus,
      timerStartTime,
      callingQueueOpportunity,
      changeOwnerAgentStatus,
      agentStatusApiLoading,
    } = props;
    const { ACTIVE, INACTIVE } = Constants.agentStatus;

    if (agentStatusApiLoading) {
      //block redendant change status call
      return;
    }

    if (agentStatus == ACTIVE && !ownerCallingStatus) {
      (!isOwnerTelesalesAgent || !process.env.SAPPHIRE_OWNER_FLAG) &&
        changeAgentStatus(INACTIVE);
      isOwnerTelesalesAgent &&
        process.env.SAPPHIRE_OWNER_FLAG &&
        changeOwnerAgentStatus(INACTIVE);
      if (
        /tenant-*/.test(window.location.pathname) ||
        (!isOwnerTelesalesAgent &&
          !isBrokerTelesalesAgent &&
          !isBuilderTelesalesAgent)
      ) {
        router.navigate("/tenant-dashboard");
      } else if (isOwnerTelesalesAgent) {
        router.navigate("/owner-dashboard");
      } else if (isBrokerTelesalesAgent) {
        router.navigate("/broker-dashboard");
      } else if (isBuilderTelesalesAgent) {
        router.navigate("/builder-dashboard");
      }
      if (timerStartTime && callingQueueOpportunity) {
        const { id, readTimeCurrentAction } = callingQueueOpportunity;
        const timerEndTime = new Date().getTime();
        const readTimeData = {};
        readTimeData.readTimeCurrentAction = Math.abs(
          (timerEndTime - timerStartTime) / 1000
        );
        readTimeCurrentAction &&
          (readTimeData.readTimeCurrentAction += readTimeCurrentAction);
        if (id) {
          clientInactiveBeforeCall(id, readTimeData);
        }
      }
    } else {
      (!isOwnerTelesalesAgent || !process.env.SAPPHIRE_OWNER_FLAG) &&
        changeAgentStatus(ACTIVE);
      isOwnerTelesalesAgent &&
        process.env.SAPPHIRE_OWNER_FLAG &&
        changeOwnerAgentStatus(ACTIVE);
      setState({
        shouldPullCase: true,
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const _handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleNotificationClick = (
    notificationId,
    opportunityId,
    shouldRemoveNotificationFromList = true
  ) => {
    readNotification(notificationId);
    _handleClose();
    if (shouldRemoveNotificationFromList) {
      setNewNotifications([]); // this is done as recent notifications call will bring the updated notification list with it
    }
    props.router.navigate(`/opportunity/${opportunityId}`);
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  let getUnreadNotifications = () => {
    let notifications = [
      ...oldNotifications,
      ...newNotifications,
      ...todaysNotifications,
    ];
    return notifications.filter((notificationData) => {
      return !notificationData.readAt;
    });
  };

  const getNotificationDialog = () => {
    return (
      <div className="notification-dialog">
        <IconButton
          aria-describedby="simple-popover"
          variant="contained"
          onClick={handleClick}
        >
          <Badge badgeContent={getUnreadNotifications().length} color="secondary">
            <NotificationsIcon className="notification-icon" />
          </Badge>
        </IconButton>
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={_handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="h6" className="notification-heading">
            Payment Notifications
          </Typography>
          <Paper className="notification-paper">
            <div className="sub-heading">New</div>
            {newNotifications.length || todaysNotifications.length ? (
              [...newNotifications, ...todaysNotifications].map(
                (notification, index) => (
                  <ListItem
                    className="notification-row"
                    key={index}
                    button
                    onClick={() => {
                      handleNotificationClick(
                        notification.id,
                        notification.opportunityId,
                        true
                      );
                    }}
                  >
                    <FiberManualRecord
                      className="unread-mark"
                      style={{
                        color: `${
                          notification.readAt && notification.readAt != "null"
                            ? "#ffffff00"
                            : "red"
                        }`,
                      }}
                    />
                    <Typography>Opportunity {notification.opportunityId}</Typography>
                  </ListItem>
                )
              )
            ) : (
              <div className="empty-message">No new notifications</div>
            )}
            <div className="sub-heading">Earlier</div>
            {oldNotifications.length ? (
              oldNotifications.map((notification, index) => (
                <ListItem
                  className="notification-row"
                  key={index}
                  button
                  onClick={() =>
                    handleNotificationClick(
                      notification.id,
                      notification.opportunityId
                    )
                  }
                >
                  <FiberManualRecord
                    className="unread-mark"
                    style={{
                      color: `${
                        notification.readAt && notification.readAt != "null"
                          ? "#ffffff00"
                          : "red"
                      }`,
                    }}
                  />
                  <Typography>Opportunity {notification.opportunityId}</Typography>
                </ListItem>
              ))
            ) : (
              <div className="empty-message">No old notifications</div>
            )}
          </Paper>
        </Popover>
      </div>
    );
  };

  const head = getMeta({
    title: props.title,
    isPlatformMakaan: isPlatformMakaan,
    description: props.description,
  });
  const isLsqKey = readBrowserStorage(constant.CHECK_LSQ);
  return (
    <div data-testid="header-container">
      <ErrorDialog
        onClose={handleDialogClose}
        message={errorDialogMessage}
        open={!!errorDialogMessage}
      />
      <Meta
        title={head.title}
        description={head.description}
        link={head.link}
        meta={head.meta}
      />
      {userInfo ? (
        <AppBar position="static">
          <Toolbar className="header-toolbar">
            <IconButton
              onClick={handleToggle}
              data-testid="drawer-toggle-button"
              edge="start"
              color="inherit"
              aria-label="menu"
              title="Toggle Menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ color: "white" }}>
              {isPlatformMakaan ? "Makaan Sales" : "Housing Sales"}
            </Typography>
            <div className="agent-status-wrapper">
              {isLsqKey && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              )}
              {isNotificationVisible && getNotificationDialog()}
              {(isOwnerTelesalesAgent ||
                isBrokerTelesalesAgent ||
                isBuilderTelesalesAgent ||
                roles.includes(Constants.housingTMAgentRole)) &&
                agentStatus == ACTIVE && (
                  <Tooltip title={state.statusMessage}>
                    <AccountCircleIcon
                      className="online"
                      style={{ color: "#0af50a", fontSize: "40px" }}
                      onClick={!ownerCallingStatus ? handleOnline : undefined}
                      onMouseEnter={() => showStatus(true)}
                      onMouseLeave={() => showStatus(false)}
                      data-testid="agent-status-icon-active"
                    />
                  </Tooltip>
                )}
              {(isOwnerTelesalesAgent ||
                isBrokerTelesalesAgent ||
                isBuilderTelesalesAgent ||
                roles.includes(Constants.housingTMAgentRole)) &&
                agentStatus == INACTIVE && (
                  <Tooltip title={state.statusMessage}>
                    <AccountCircleIcon
                      className="online"
                      style={{ fontSize: "40px" }}
                      onClick={handleOnline}
                      onMouseEnter={() => showStatus(true)}
                      onMouseLeave={() => showStatus(false)}
                      data-testid="agent-status-icon-inactive"
                    />
                  </Tooltip>
                )}
            </div>
          </Toolbar>
        </AppBar>
      ) : (
        !(props.isLoggedOut || props.hasLoginFailed) && (
          <AppBar position="static">
            <Toolbar className="header-toolbar">
              <Skeleton variant="rect" width={25} height={25} />
              <Skeleton height={30} width={170} />
              <Skeleton variant="rect" width={25} height={25} />
            </Toolbar>
          </AppBar>
        )
      )}
      {userInfo && state.open && (
        <Drawer
          open={state.open}
          onClose={() => setState({ open: false })}
          data-testid="app-drawer"
          title={state.open ? "Drawer Open" : "Drawer Closed"}
        >
          <List>
            <ListItem className="user-icon" style={{ color: "#B3B4B5" }}>
              <Avatar
                style={{
                  color: "#191919",
                  backgroundColor: "#B3B4B5",
                  marginRight: "25px",
                  height: "35px",
                  width: "35px",
                }}
              >
                {userInfo.firstName ? userInfo.firstName[0] : ""}
              </Avatar>
              {userInfo.firstName ? userInfo.firstName : ""}
            </ListItem>
          </List>

          <Divider />
          <br />
          <div className="header-nav">
            {navigationsRef.current.map((navigation) => getMenuItem(navigation))}
            <br />
            <Divider />
            <br />
            <div className="menu-item">
              <MenuItem
                style={styles.menuItem}
                onClick={logoutClicked}
                data-testid="logout-button"
              >
                <PowerSettingsNew
                  style={{ color: "#B3B4B5", paddingRight: "20px" }}
                ></PowerSettingsNew>
                Logout
              </MenuItem>
            </div>
          </div>
        </Drawer>
      )}
      <div style={{ display: "none" }}>
        <button data-testid="open-menu-button" onClick={handleOpen}>
          Open Drawer
        </button>

        <button data-testid="close-menu-button" onClick={handleClose}>
          Close Drawer
        </button>
      </div>
    </div>
  );
  /*  } */
};

Header.propTypes = {
  title: PropTypes.string,
  logout: PropTypes.func,
  getUser: PropTypes.func,
  userInfo: PropTypes.object,
  description: PropTypes.string,
  hasLoginFailed: PropTypes.bool,
  variousConfig: PropTypes.object,
  getVariousConfig: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
