import React from "react";
import { datadogLogs } from "@datadog/browser-logs";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    datadogLogs.logger.log(error.message, errorInfo, "error");
    /* eslint-disable no-console */
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1 className="ta-c pb20 pt20">
          Something went wrong. Please try after some time.
        </h1>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
