import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

function CommonLoader({ size, thickness, propsStyle }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <div
      className="common-loader"
      style={propsStyle || style}
      data-testid="common-loader"
    >
      <CircularProgress
        style={{ color: "#633EE0" }}
        size={size || 60}
        thickness={thickness || 5}
      />
    </div>
  );
}

CommonLoader.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
};

export default CommonLoader;
