import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const ErrorDialog = (props) => {
  const { message, open, onClose } = props;
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ color: "red" }} disableTypography>
        Error
      </DialogTitle>
      <DialogContent> {message}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ErrorDialog;
