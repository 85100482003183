import "./App.scss";

import DateFnsUtils from "@date-io/date-fns";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import InactiveProfileDialog from "../InactiveProfileDialog/InactiveProfileDialog";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from "prop-types";
import React from "react";
import TagManager from "react-gtm-module";
import routes from "../../routes";
import { trackPerformanceMetrics } from "../../tracking/tracking";
import ErrorBoundary from "../ErrorBoundary";

const tagManagersArgs = {
  gtmId: "GTM-WJCZWRB",
};
TagManager.initialize(tagManagersArgs);

if (process.env.BUILD_ENV === "prod") {
  trackPerformanceMetrics();
}
const App = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <div className="App">
      <ErrorBoundary>
        <Header {...props} />
      </ErrorBoundary>
      <div className="housing-app">
        <ErrorBoundary>{routes}</ErrorBoundary>
      </div>
      <ErrorBoundary>
        <InactiveProfileDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </div>
  </MuiPickersUtilsProvider>
);

App.propTypes = {
  children: PropTypes.node,
};

export default App;
