import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import MuiSnackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const mapStateToProps = ({ snackbar }) => ({ snackbar });

const Snackbar = ({ snackbar }) => {
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(3000);
  const [type, setType] = useState("info");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (snackbar) {
      const { state, type, duration, message } = snackbar;
      if (state && message) {
        setOpen(true);
        setMessage(message);
        if (type) {
          setType(type);
        } else {
          setType("info");
        }
        if (duration) {
          setDuration(duration);
        } else {
          setDuration(3000);
        }
      }
    }
  }, [snackbar]);

  const onCloseHandler = useCallback(() => {
    setOpen(false);
  }, [snackbar]);

  return (
    <div className="lookup-snackbar">
      <MuiSnackbar open={open} autoHideDuration={duration} onClose={onCloseHandler}>
        <Alert onClose={onCloseHandler} severity={type}>
          {message}
        </Alert>
      </MuiSnackbar>
    </div>
  );
};

export default connect(mapStateToProps)(React.memo(Snackbar));
