import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Dialog as DialogFooter,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import Snackbar from "../Snackbar/Snackbar";
import {
  resetFileChoosenForTicket,
  resetCreatedTicketId,
} from "../../redux/actions/detailDialog.actions";
import { uploadFile } from "../../redux/actions/documents.actions";
import Constants from "../../utils/CommonConstants";

const mapStateToProps = (state) => ({
  fileChoosenForTicket: state.fileChoosenForTicket,
  dialog: state.dialog,
  createdTicketId: state.createdTicketId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetFileChoosenForTicket: bindActionCreators(
      resetFileChoosenForTicket,
      dispatch
    ),
    uploadFile: bindActionCreators(uploadFile, dispatch),
    resetCreatedTicketId: bindActionCreators(resetCreatedTicketId, dispatch),
    dispatch,
  };
};

const Footer = (props) => {
  const [state, _setState] = useState({
    autoHideDuration: Constants.snackbarDuration,
    showDialog: false,
  });

  useEffect(() => {
    const nextProps = props;
    if (nextProps.dialog) {
      setState({ showDialog: nextProps.dialog.state });
    }
    if (nextProps.createdTicketId) {
      const formData = props.fileChoosenForTicket;
      if (formData) {
        formData.append("objectId", nextProps.createdTicketId);
        props.uploadFile(formData);
      }
      props.resetFileChoosenForTicket();
      props.resetCreatedTicketId();
    }
  }, [props]);

  const setState = (newState) => _setState((state) => ({ ...state, ...newState }));

  const handleClose = () => {
    setState({ showDialog: false });
    props.dispatch({ type: Constants.CLOSE_COMMON_DIALOG });
  };
  const { dialog } = props;
  return (
    <div>
      {state.showDialog && (
        <DialogFooter
          maxWidth="md"
          scroll={scroll}
          modal={false}
          open={state.showDialog}
          onRequestClose={handleClose}
        >
          <DialogTitle>{dialog.message}</DialogTitle>
          <DialogContent>{dialog.content}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose} key="ok">
              Ok
            </Button>
          </DialogActions>
        </DialogFooter>
      )}
      <Snackbar />
    </div>
  );
};

Footer.propTypes = {
  dispatch: PropTypes.func,
  dialog: PropTypes.object,
  snackbar: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
