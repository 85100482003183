import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import constant from "../utils/CommonConstants";
import CommonLoader from "./CommonLoader";
const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});
const ProtectedRoute = ({ component: Component, roles, userInfo }) => {
  const { userHasRequiredRole } = constant;

  if (!userInfo) {
    return <CommonLoader />;
  }
  if (!userHasRequiredRole(userInfo?.roles, roles)) {
    return <Navigate to="/unauthorized" />;
  }

  return <Component />;
};
export default connect(mapStateToProps, null)(ProtectedRoute);
