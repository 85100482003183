import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Constants from "../../utils/CommonConstants";
import { setInactiveProfile } from "../../redux/actions/profile.actions";

const mapStateToProps = ({ modalInactiveProfileHousing }) => ({
  modalInactiveProfileHousing,
});

const mapDispatchToProps = (dispatch) => ({
  setInactiveProfile: bindActionCreators(setInactiveProfile, dispatch),
  dispatch,
});

const InactiveProfileDialog = ({
  modalInactiveProfileHousing: { isActive = false, message = {} } = {},
  setInactiveProfile,
}) => {
  const onConfirmAlert = () => {
    setInactiveProfile(false);
  };

  const {
    title = Constants.CONFIRM_DIALOG_HEADER,
    subTitle = Constants.CONFIRM_NOTIFICATION,
  } = message || {};

  const formattedSubTitle = subTitle
    .split("\n")
    .map((line, index) => <p key={`${line}-${index}`}>{line}</p>);

  return (
    <ConfirmDialog
      title={title}
      subTitle={formattedSubTitle}
      confirmDialogFlag={isActive}
      onokDialogBtn={onConfirmAlert}
      okButtonOnly={true}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(InactiveProfileDialog));
